@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif !important;
}

body {
  background: #000;
  color: #fff;
}

a {
  color: #05EE3B !important;
  text-decoration: none;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 9px;
  display: none;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  border: 2px solid var(--white);
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/


.active_sidebar .MuiTypography-root {
  color: #05EE3B;
}

.sidebar_text .MuiTypography-root {
  line-height: 1.3 !important;
  font-size: 14px;
}

.search_field {
  background: #151515 !important;
}

.MuiInputBase-root:hover {
  border: 1px solid #454545 !important;
  border-radius: 0px !important;
}

.search_field .MuiInputAdornment-positionEnd {
  right: 0px !important;
  top: 3.5px !important;
}

.table_icon {
  width: 30px;
  height: 30px;
  padding: 5px;
}

@media (max-width:600px) {
  .table_search_head .MuiBox-root {
    width: 100%;
  }
}

.MuiPaginationItem-root {
  border: 1px solid #FFF !important;
  color: #FFF !important;
}

.MuiPaginationItem-root .MuiSvgIcon-root {
  color: #FFF !important;
}


.Mui-disabled {
  -webkit-text-fill-color: #999 !important;
}

.image-container {
  position: relative;
  display: inline-block;
}

.hover-image {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateX(-10%);
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  justify-content: center;
}

.hover-image img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}


.sidebarfont{
  font-size: 14px;
}
.image-container img:hover {
  transform: scale(1.2);
}
.demochart {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.democolumnchart{
  border-right: 1px solid #151515;
}

.demochart > div {
  flex: 1 1 45%; /* Allows flexibility and ensures charts take up 45% of the container */
  max-width: 600px;
  min-width: 300px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .demochart > div {
    flex: 1 1 100%; /* Full width on smaller screens */
  }
  .democolumnchart{
    border-right: none;
    border-bottom: 1px solid #151515;

  }
}

.filterinput{
  display: flex;
  align-items: center;
  gap: 6px;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.red{
  background-color: red;
  padding: 5px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
}
.green{
  background-color: #05EE3B;
  padding: 5px;
  height: 6px;
  width: 6px;
  border-radius: 100%;
}
.tabletext{
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  text-align: left;
  padding: 16px;
  color: #fff;
  font-weight: 300;
  white-space: nowrap;
}